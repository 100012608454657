import Vue from 'vue'
import Router from 'vue-router'

import { useAuthStore } from '@/store/auth'

import getEnv from '@/utils/env'
import _ from 'lodash'

import MainLayout from '@/layouts/Main.vue'

const Home = () => import('@/views/Home.vue')
const LoginCallback = () => import('@/views/LoginCallback/LoginCallback.vue')

const ListRequests = () => import('@/views/Requests/List/List.vue')
const ShowRequest = () => import('@/views/Requests/Show/Show.vue')
const CreateRequest = () => import('@/views/Requests/Create/Create.vue')

const ListApprovals = () => import('@/views/Approvals/List/List.vue')
const ShowApproval = () => import('@/views/Approvals/Show/Show.vue')

const ListAccounts = () => import('@/views/Accounts/List/List.vue')
const ShowAccount = () => import('@/views/Accounts/Show/Show.vue')

const NotFound = () => import('@/components/ErrorDisplays/404.vue')
const Forbidden = () => import('@/components/ErrorDisplays/403.vue')

Vue.use(Router)

const routes = [
  { path: '/login/callback', component: LoginCallback },
  {
    path: '',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: Home
      },
      {
        path: '/404',
        name: 'not-found',
        component: NotFound
      },
      {
        path: '/403',
        name: 'forbidden',
        component: Forbidden
      }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/accounts',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'list-accounts',
        component: ListAccounts
      },
      {
        path: ':login',
        name: 'show-account',
        component: ShowAccount
      }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/requests',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'list-requests',
        component: ListRequests
      },
      {
        path: 'create',
        name: 'create-request',
        component: CreateRequest,
        props: route => ({
          guestAccountUsername: route.params.guestAccountUsername,
          type: route.params.type
        })
      },
      {
        path: ':rawsDocumentNumber',
        name: 'show-request',
        component: ShowRequest
      }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/approvals',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'list-approvals',
        component: ListApprovals
      },
      {
        path: ':rawsDocumentNumber',
        name: 'show-approval',
        component: ShowApproval
      }
    ],
    meta: { requiresAuth: true }
  }
]

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach(async(to, from, next) => {
  const authStore = useAuthStore()
  authStore.init()
  if (process.env.VUE_APP_SKIP_AUTH !== '1') {
    const loggedIn = await authStore.$auth.isAuthenticated()

    if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
      authStore.login(to.fullPath)
      return
    }
  }
  next()
})

const DEFAULT_TITLE = `${getEnv('VUE_APP_TITLE_SHORT')}`
router.afterEach((to, from) => {
  let title = ''

  if (to.params.type) {
    title = title.concat(`${_.startCase(to.params.type)} `)
  }

  if (to.meta.title) {
    title = title.concat(`${to.meta.title}`)
  }

  // document.title = _.isEmpty(title)
  //   ? DEFAULT_TITLE
  //   : title.concat(` | ${DEFAULT_TITLE}`)
  document.title = DEFAULT_TITLE
})

router.pushAsync = (route) => {
  return new Promise((resolve, reject) => {
    router.push(route, resolve, reject)
  })
}

export default router
