import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#192b40', // '#BF0000', // #E53935
        secondary: '#d5514f', // colors.red.lighten1, //'#E57373 ', // colors.red.lighten4, // #FFCDD2
        accent: '#192b40', //'#578A41', // colors.indigo.base // #3F51B5
        success: '#578A41',
        warning: '#D6805A',
        background: '#FFF7F3',
        anchor: '#d5514f',
        error: colors.red.darken2
      }
    }
  }
})
