import Vue from 'vue'
import { defineStore } from 'pinia'
import OktaVue from '@okta/okta-vue'
import { OktaAuth } from '@okta/okta-auth-js'
import getEnv from '@/utils/env'
import ApiService from '@/service/api.service'
import { useUserStore as userStore } from '@/store/user'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    $auth: null,
    token: null
  }),
  getters: {
    loggedIn: (state) => {
      return Boolean(state.$auth.getAccessToken())
    }
  },
  actions: {
    init() {
      if (
        this.$auth == null &&
        (Vue.prototype.$browserDetect.isChrome ||
          Vue.prototype.$browserDetect.isChromeIOS ||
          Vue.prototype.$browserDetect.isFirefox ||
          Vue.prototype.$browserDetect.isEdge)
      ) {
        const oktaAuth = new OktaAuth({
          issuer: getEnv('VUE_APP_OKTA_URL'),
          clientId: getEnv('VUE_APP_OKTA_CLIENT_ID'),
          redirectUri: `${location.origin}/login/callback`,
          postLogoutRedirectUri: `${location.origin}/`,
          scopes: ['openid', 'profile', getEnv('VUE_APP_OKTA_SCOPE')],
          tokenManager: { storage: 'memory' }
        })
        Vue.use(OktaVue, { oktaAuth })
        this.$auth = Vue.prototype.$auth
        ApiService.setHeader(this.$auth.getAccessToken())
      }
    },
    login(redirect = '/') {
      this.$auth.signInWithRedirect({ originalUri: redirect || '/' })
    },
    async logout(redirectState = '/') {
      ApiService.removeHeader()
      await this.$auth.signOut({
        state: encodeURIComponent(redirectState)
      })
    },
    async loginCallback() {
      await this.$auth.handleLoginRedirect()
    },
    async updateToken() {
      const authenticated = await this.$auth.isAuthenticated()

      if (authenticated) {
        const token = this.$auth.getAccessToken()
        this.token = token
        if (token) {
          ApiService.setHeader(token)
          await this.updateUser()
        }
      } else {
        this.token = null
      }
    },
    async updateUser() {
      await userStore().fetchUser()
    }
  }
})
