import ApiService from '@/service/api.service.js'
import { defineStore } from 'pinia'
let avatar = require('../assets/default-avatar.gif')

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
    avatar: null
  }),
  getters: {
    getUser(state) {
      return state.user
    }
  },
  actions: {
    async fetchUser() {
      if (this.user == null) {
        ApiService.customRequest({
          method: 'get',
          url: '/me',
          headers: { 'Content-Type': 'application/json' }
        })
          .then((result) => {
            this.user = result.data
            this.avatar = avatar
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }
})
