import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueCompositionAPI from '@vue/composition-api'
import router from './router'
import vuetify from './plugins/vuetify'
import moment from 'moment-timezone'
import lodash from 'lodash'
import lodashInflection from 'lodash-inflection'
import browserDetect from 'vue-browser-detect-plugin'

Object.defineProperty(Vue.prototype, '$moment', { value: moment })
Object.defineProperty(Vue.prototype, '_', { value: lodash })
Object.defineProperty(Vue.prototype, '$lodashInflection', { value: lodashInflection})

const pinia = createPinia()

Vue.use(pinia)
Vue.use(PiniaVuePlugin)
Vue.use(VueCompositionAPI)

Vue.use(browserDetect)

Vue.use(router)

Vue.config.productionTip = false

new Vue({
  pinia,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
