<template>
  <v-app>
    <app-bar></app-bar>
    <v-main>
      <router-view />
    </v-main>
    <the-footer></the-footer>
  </v-app>
</template>

<script>
// @ is an alias to /src
import AppBar from '@/components/AppBar.vue'
import TheFooter from '@/components/TheFooter.vue'

export default {
  name: 'Main',
  components: { AppBar, TheFooter }
}
</script>

<style scoped>
.theme--light.v-application {
  background-color: rgba(255, 234, 226, 0.4);
}
.theme--light.v-footer {
  background-color: rgba(255, 234, 226, 0.4);
}
</style>
