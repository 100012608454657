<template>
  <v-app-bar app color="primary" dark flat>
    <v-toolbar-title>
      <router-link to="/" custom v-slot="{ navigate }">
        <span @click="navigate" @keypress.enter="navigate" role="link">
          Self-Service Portal
          <!-- <v-icon color="secondary">mdi-send-outline</v-icon> -->
        </span>
      </router-link>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn v-if="loggedIn" text plain to="/accounts">
      <v-icon></v-icon> Accounts
    </v-btn>
    <v-btn v-if="loggedIn" text plain to="/approvals">
      <v-icon></v-icon> Approvals
    </v-btn>
    <v-btn v-if="loggedIn" text plain to="/requests">
      <v-icon></v-icon> Requests
    </v-btn>
    <v-btn v-if="loggedIn" depressed color="secondary" to="/requests/create">
      <v-icon></v-icon> New Request
    </v-btn>
    <v-menu v-if="loggedIn && loggedInUser" offset-y nudge-bottom="10">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ml-5 mr-0" icon v-bind="attrs" v-on="on">
          <v-avatar size="45">
            <img :src="avatar" />
          </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img :src="avatar" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ loggedInUserFullName }}</v-list-item-title>
            <v-list-item-subtitle>{{
              loggedInUser.primaryEmail
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item link>
          <v-list-item-title @click="logout">Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState } from 'pinia'
import { mapActions } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { useUserStore } from '@/store/user'

export default {
  name: 'AppBar',
  data() {
    return {
      loggedInUser: null
    }
  },
  watch: {
    user(val) {
      this.loggedInUser = val
    }
  },
  computed: {
    loggedInUserFullName() {
      return `${this.user.firstName} ${this.user.lastName}`
    },
    ...mapState(useAuthStore, ['loggedIn']),
    ...mapState(useUserStore, ['user', 'avatar'])
  },
  methods: {
    toHome() {},
    ...mapActions(useAuthStore, ['logout'])
  }
}
</script>

<style scoped>
.mdi-send-outline::before {
  transform: rotate(-25deg);
  margin-top: -5px;
}
</style>
