<template>
  <v-footer color="background" class="justify-center">
    <span class="secondary--text"> &copy; Rakuten Group, Inc. </span>
  </v-footer>
</template>

<script>
export default {
  name: 'TheFooter',
  data() {
    return {}
  }
}
</script>

<style scoped>
</style>
