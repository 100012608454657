import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"app":"","color":"primary","dark":"","flat":""}},[_c(VToolbarTitle,[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(" Self-Service Portal ")])]}}])})],1),_c(VSpacer),(_vm.loggedIn)?_c(VBtn,{attrs:{"text":"","plain":"","to":"/accounts"}},[_c(VIcon),_vm._v(" Accounts ")],1):_vm._e(),(_vm.loggedIn)?_c(VBtn,{attrs:{"text":"","plain":"","to":"/approvals"}},[_c(VIcon),_vm._v(" Approvals ")],1):_vm._e(),(_vm.loggedIn)?_c(VBtn,{attrs:{"text":"","plain":"","to":"/requests"}},[_c(VIcon),_vm._v(" Requests ")],1):_vm._e(),(_vm.loggedIn)?_c(VBtn,{attrs:{"depressed":"","color":"secondary","to":"/requests/create"}},[_c(VIcon),_vm._v(" New Request ")],1):_vm._e(),(_vm.loggedIn && _vm.loggedInUser)?_c(VMenu,{attrs:{"offset-y":"","nudge-bottom":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-5 mr-0",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VAvatar,{attrs:{"size":"45"}},[_c('img',{attrs:{"src":_vm.avatar}})])],1)]}}],null,false,3012627368)},[_c(VList,[_c(VListItem,[_c(VListItemAvatar,[_c('img',{attrs:{"src":_vm.avatar}})]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.loggedInUserFullName))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.loggedInUser.primaryEmail))])],1)],1)],1),_c(VDivider),_c(VList,[_c(VListItem,{attrs:{"link":""}},[_c(VListItemTitle,{on:{"click":_vm.logout}},[_vm._v("Logout")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }