<template>
  <router-view v-if="isBrowserSupported"/>
  <v-container v-else>
    Browser not supported
  </v-container>
</template>

<script>
import ApiService from '@/service/api.service'
import { mapState, mapActions } from 'pinia'
import { useAuthStore } from '@/store/auth'

export default {
  name: 'App',
  computed: {
    isBrowserSupported() {
      return this.$browserDetect.isChrome || this.$browserDetect.isChromeIOS || this.$browserDetect.isFirefox || this.$browserDetect.isEdge
    },
    ...mapState(useAuthStore, ['loggedIn'])
  },
  methods: {
    async init() {
      if (this.isBrowserSupported) {
        ApiService.init(process.env.VUE_APP_API_PATH)
        await this.updateToken()
      }
    },
    ...mapActions(useAuthStore, ['updateToken'])
  },
  created() {
    this.init()
  }
}
</script>
